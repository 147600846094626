<template>
  <ValidationObserver
    class="bg-white modal-card rounded"
    style="width: 50vw"
    v-slot="{ invalid }"
  >
    <div class="bg-primary-100 p-4 text-xl">Création d'un sondage</div>
    <div
      class="grid grid-cols-4 gap-3 p-5 overflow-y-auto"
      style="max-height: 80vh"
    >
      <div class="col-span-4">
        <ValidationProvider
          name="Question"
          rules="required|min:10|max:180"
          v-slot="{ errors }"
        >
          <t-input-group
            label="Titre"
            :variant="{ 'danger': errors[0] }"
            :feedback="errors[0]"
          >
            <t-input
              v-model="survey.question"
              :variant="{ 'danger': errors[0] }"
            />
          </t-input-group>
        </ValidationProvider>
      </div>
      <div class="col-span-4 flex flex-col gap-5">
        <div
          v-for="(option, i) in survey.options"
          :key="i"
        >
          <ValidationProvider
            class="flex justify-between"
            :name="`Réponse ${i + 1}`"
            rules="required|min:3|max:180"
            v-slot="{ errors }"
          >
            <t-input-group
              class="w-full"
              :label="`Réponse ${i + 1}`"
              :variant="{ 'danger': errors[0] }"
              :feedback="errors[0]"
            >
              <t-input
                v-model="survey.options[i]"
                placeholder="Choisir une réponse..."
                :variant="{ 'danger': errors[0] }"
                :disabled="survey.options[i] === 'Ne souhaite pas répondre'"
              />
            </t-input-group>
            <div
              v-if="i > 1"
              class="col-span-1 p-3 flex items-end justify-center"
              @click="removeOption(i)"
            >
              <i class="fas fa-times text-red-700" />
            </div>
          </ValidationProvider>
        </div>
        <div class="flex space-x-2">
          <t-button
            @click="addOption"
            class="w-7/12"
          >+ Ajouter une option</t-button>
          <t-button
            @click="addNoReplyOption"
            class="w-5/12"
            :disabled="noReplyAdded"
          >+ Ajouter option 'ne souhaite pas répondre'</t-button>
        </div>
        <t-input-group
          label="Groupes (facultatif)"
          v-if="usersGroups && usersGroups.length"
        >
          <t-rich-select
            multiple
            :close-on-select="false"
            :options="usersGroups"
            placeholder="Sélectionner des groupes"
            v-model="groupsSelected"
          ></t-rich-select>
          <!-- {{ groupsSelected }} -->
        </t-input-group>
        <div class="flex space-x-8">
          <t-input-group label="Plusieurs réponses possibles">
            <t-toggle v-model="survey.multiple_answers" />
          </t-input-group>
          <t-input-group label="Possibilité de changer de réponse">
            <t-toggle v-model="survey.is_reanswerable" />
          </t-input-group>
        </div>
      </div>
      <ValidationProvider
        class="col-span-4 md:col-span-2"
        name="Début du sondage"
        rules="required"
        v-slot="{ errors }"
        v-if="domainUuid !== '2bc5decc-cd2e-46ad-92d7-f17234fdeebd'"
      >
        <t-input-group
          label="Début du sondage"
          :variant="{ 'danger': errors[0] }"
          :feedback="errors[0]"
        >
          <t-datepicker
            timepicker
            required
            class="mb-10"
            v-model="survey.start_at"
            :maxDate="survey.end_at"
            placeholder="Clique pour séléctionner la date de début du sondage..."
            dateFormat="Y-m-d H:i:S"
            userFormat="d/m/Y H:i"
          />
        </t-input-group>
      </ValidationProvider>
      <ValidationProvider
        class="col-span-4 md:col-span-2"
        name="Début du sondage"
        rules="required"
        v-slot="{ errors }"
        v-if="domainUuid !== '2bc5decc-cd2e-46ad-92d7-f17234fdeebd'"
      >
        <t-input-group
          label="Fin du sondage"
          :variant="{ 'danger': errors[0] }"
          :feedback="errors[0]"
        >
          <t-datepicker
            timepicker
            required
            v-model="survey.end_at"
            :minDate="survey.start_at"
            placeholder="Clique pour séléctionner la date de fin du sondage..."
            dateFormat="Y-m-d H:i:S"
            userFormat="d/m/Y H:i"
          />
        </t-input-group>
      </ValidationProvider>

    </div>
    <div class="bg-gray-50 px-4 py-3 flex gap-3 justify-between">
      <div class="flex gap-3 items-center">
        <label for="active">Actif</label>
        <t-toggle
          class="ml"
          v-model="survey.is_enabled"
        ></t-toggle>
      </div>
      <div v-if="domain.can_avoid_notifications" class="ml-8 flex gap-3 items-center">
        <label>Ne pas notifier</label>
        <t-toggle
          class=""
          v-model="survey.to_notify"
          :value="false"
          :uncheckedValue="true"
        />
      </div>
      <div class="flex gap-3">
        <t-button
          class="m-0"
          @click.prevent="$parent.close()"
          variant="secondary"
        >Annuler</t-button>
        <t-button
          :disabled="invalid"
          variant="success"
          @click.prevent="create"
        >Sauvegarder</t-button>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
/* eslint-disable */
import { http } from '@/services/api.service'
import { format, parse } from 'date-fns'
import { fr } from 'date-fns/locale'
import { mapGetters } from 'vuex';

export default {
  name: 'app-surveys-create',
  props: { domainUuid: String },
  data() {
    return {
      survey: {
        options: ['', ''],
        noReplyAdded: false,
        groups: [],
        to_notify: true
      },
      usersGroups: [],
      groupsSelected: []
    }
  },
  async beforeMount () {
    // Fetch groups
    await this.fetchGroups()
  },
  computed: {
    ...mapGetters([
      'domain'
    ])
  },
  methods: {
    async fetchGroups () {
      try {
        const response = await http.get('/groups')
        this.usersGroups = response.data.map(group => ({
          value: group.uuid,
          text: group.label
        }))
      } catch (error) {
        console.error('Error fetching groups:', error)
      }
    },
    create() {
      const formData = new FormData()
      Object.keys(this.survey).forEach(key => {
        if (Array.isArray(this.survey[key])) {
          this.survey[key].forEach(item => {
            formData.append(`${key}[]`, item)
          })
        } else {
          formData.append(key, this.survey[key])
        }
      })

      this.groupsSelected.forEach(groupUuid => {
        formData.append('groupUuids[]', groupUuid)
      })

      http.post('/surveys', formData).then(({ data }) => {
        this.$emit('created', data)
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à créer ce sondage',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      }).finally(() => {
        this.$parent.close()
      })
    },
    addOption() {
      if (this.survey.options[0].trim() === '' || this.survey.options[1].trim() === '') return
      const noReplyIndex = this.survey.options.findIndex(option => option === 'Ne souhaite pas répondre')
      if (noReplyIndex > -1) {
        this.survey.options.splice(noReplyIndex, 0, '')
      } else {
        this.survey.options.push('')
      }
    },
    removeOption(index) {
      if (this.survey.options[index] === 'Ne souhaite pas répondre') {
        this.noReplyAdded = false
      }
      this.survey.options.splice(index, 1)
    },
    addNoReplyOption() {
      this.removeNoReplyOption()
      this.survey.options.push('Ne souhaite pas répondre')
      this.noReplyAdded = true
    },
    removeNoReplyOption() {
      const index = this.survey.options.indexOf('Ne souhaite pas répondre')
      if (index > -1) {
        this.survey.options.splice(index, 1)
      }
    }
  }
}
</script>
